






































































































































import vueFilePond, { VueFilePondComponent } from 'vue-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import BackBar from '@/components/BackBar.vue';
import jwlTaskMeta from '@/components/jwlTaskMeta.vue';
import { ExtendedRunTask } from '@/store/interface/Task';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

const JwlProfileImage = () => import('common-modules/src/components/CommonProfileImage.vue');
const LmsCourseDocument = () => import('@/components/LmsCourseDocument.vue');
const IconArrowToTopDuotone = () => import('common-modules/src/assets/fa-icons/duotone/arrow-to-top.svg');
const IconArrowToTopRegular = () => import('common-modules/src/assets/fa-icons/regular/arrow-to-top.svg');
const TaskPublish = () => import('@/components/TaskPublish.vue');
const CommonError = () => import('common-modules/src/components/CommonError.vue');
const CommonIcon = () => import('common-modules/src/components/CommonIcon.vue');

const STATUS_NOTHING = 0;
const STATUS_SUBMITTING = 1;
const STATUS_SUCCESS = 2;
const STATUS_ERROR = -1;

@Component({
  components: {
    JwlSubmitRow,
    BackBar,
    FilePond,
    IconArrowToTopDuotone,
    IconArrowToTopRegular,
    LmsCourseDocument,
    CommonError,
    CommonIcon,
    JwlProfileImage,
    jwlTaskMeta,
    TaskPublish,
  },
})
export default class JwlTaskSubmitWork extends Vue {
  $refs!: {
    filePond: VueFilePondComponent;
  }

  loaded = false;
  task: ExtendedRunTask|null = null;
  error = null;
  work: any = {
    id: null,
    files: [],
    publishedAt: null,
    grade: null,
  };

  submitStatus = STATUS_NOTHING;
  publishStatus = STATUS_NOTHING;

  @Watch('$route')
  fetchData (): void {
    this.loaded = false;
    this.$store.dispatch('getData', `task/${this.$route.params.task}`)
      .then((data) => {
        this.task = data.task;
        if (data.work) {
          Object.assign(this.work, data.work);
        }
        this.loaded = true;
      })
      .catch((e) => {
        this.error = e;
      });
  }

  submitForm (): void {
    if (this.submitStatus !== STATUS_SUBMITTING) {
      this.submitStatus = STATUS_SUBMITTING;
      const FD = new FormData();
      this.$refs.filePond.getFiles().forEach((filePondFile) => {
        FD.append('workFile[]', filePondFile.file);
      });

      this.$store.dispatch('postData', {
        url: `task/${this.$route.params.task}`,
        formData: FD,
      }).then((data) => {
        Object.assign(this.work, data);
        this.$refs.filePond?.removeFiles();
        this.submitStatus = STATUS_SUCCESS;
        setTimeout(() => {
          this.submitStatus = STATUS_NOTHING;
        }, 2500);
      }).catch((e) => {
        this.error = e;
        this.submitStatus = STATUS_ERROR;
      });
    }
  }

  publishForm (): void {
    if (this.submitStatus !== STATUS_SUBMITTING && this.publishStatus !== STATUS_SUBMITTING) {
      this.publishStatus = STATUS_SUBMITTING;
      this.$store.dispatch('publishTask', this.$route.params.task)
        .then((data) => {
          Object.assign(this.work, data);
          this.publishStatus = STATUS_SUCCESS;
          setTimeout(() => {
            this.publishStatus = STATUS_NOTHING;
          }, 2500);
        }).catch((e) => {
          this.error = e;
          this.publishStatus = STATUS_ERROR;
        });
    }
  }

  localizedDate (dateString: string): string {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  get deleteUrl (): string {
    return `task/${this.$route.params.task}`;
  }

  get gradeThemeClass (): string {
    return `jwl-submit-task--${this.task?.programmeType}`;
  }

  get gradeSubmissionClass (): string {
    const submitted = this.work;
    const baseClass = 'jwl-submit-task__grade-view';

    if (submitted.id && this.task) {
      if (submitted.grade) {
        if (submitted.grade.date) {
          if (this.task.maxScore > 0 && submitted.grade.score === 0) {
            return `${baseClass}--0-grade`;
          }
          return `${baseClass}--grade`;
        }
        return `${baseClass}--unpublished`;
      }
      return `${baseClass}--ungraded`;
    }
    return `${baseClass}--nothing`;
  }

  mounted (): void {
    this.fetchData();
  }
}
